<template>
  <div class="container pd col-start-center">
    <headBar
      :title="$store.state.flagJson.type ? '虎鹿瘦喜 新年FLAG' : '超鹿健康资料'"
      left-arrow
      @click-left="newAppBack"
    />
    <img class="banner" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/banner.png" alt="">
    <!-- 一个选择块 -->
    <div class="check-content">
      <h2 class="title f36">你下定决心努力运动，是为了？</h2>
      <ul class="check-ul row-start-center van-row--flex">
        <li :class="['check-item--single', 'row-2', 'row-start-center', checkedList.includes(item.code) ? 'checked' : '']" v-for="item in list" :key="item.code" @click="choose(item)">
          <div>
            <p class="f28 dark fw6">{{item.name}}</p>
            <p class="f22 mt-8 grey">{{item.copywriting}}</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 一个选择块 -->
    <div class="check-content">
      <h2 class="title f36">你最想{{curName}}的部位？</h2>
      <p class="f22 mt-8 grey">(可多选,最多可选两项)</p>
      <ul class="check-ul row-start-center van-row--flex">
        <li :class="['check-item--more', 'row-2', 'row-start-center', checkedList2.includes(item.code) ? 'checked' : '']" v-for="item in list2" :key="item.code" @click="choose(item, 2)">
          <img class="check-icon" :src="item.img" alt="">
          <div>
            <p class="f28 dark fw6">{{item.title}}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="button-box fixed">
      <div class="cl-button f32 fw5 row-center-center" @click="goNext">下一步（ 5/7 ）</div>
    </div>
  </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod'
import { getList } from './index'
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
export default {
  mixins: [userMixin],
  components: {
    headBar,
  },
  data() {
    return {
      list: [],
      checkedList: [],

      list2: [],
      checkedList2: [],

      curName: '改善'
    }
  },
  computed: {},
  methods: {
    newAppBack,
    choose(item, max) {
      if (max) {
        // 多选 最多max项
        let index = this.checkedList2.indexOf(item.code)
        if (index > -1) {
          this.checkedList2.splice(index, 1)
        } else {
          this.checkedList2.push(item.code)
          if (this.checkedList2.length > max) {
            this.checkedList2.shift()
          }
        }
      } else {
        // 单选
        this.checkedList = []
        this.checkedList.push(item.code)
        this.curName = item.name
      }

    },
    goNext() {
      if (this.checkedList.length === 0 || this.checkedList2.length === 0) {
        this.$toast({
          message: '请选择',
          duration: 3000
        });
        return
      }
      this.$store.commit('setFlagJson', {sportDetermination: this.checkedList, sportBodyParts: this.checkedList2})
      this.$router.push('page-6')
      this.countPoint('43','311','1481')
    }
  },
  async created() {
    await this.$getAllInfo()
    let obj = await getList(this.userId, this.token, 'sportDetermination')
    let obj2 = await getList(this.userId, this.token, 'sportBodyParts')

    this.checkedList = obj.codes
    this.list = obj.list

    if (this.checkedList.length) {
      this.curName = this.list.length ? this.list.filter((item) => item.code == this.checkedList[0])[0].name : '改善'
    }

    this.checkedList2 = obj2.codes
    this.list2 = obj2.list
    this.countPoint('43','311','1480')
  },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
.container {
  background-color: #F9FAFB;
}
</style>
